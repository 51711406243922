<style lang="less" scoped>
	body {}

	.content {
		background: #ffffff;

		.box {
			width: 1264px;
			margin: 0 auto;
		}

		.details_box {
			padding: 52px 0;
			display: flex;

			.left {
				>.icon_box {
					width: 975px;
					height: 538px;
					position: relative;
					object-fit: cover;
					.text {
						width: 100%;
						height: 110px;
						line-height: 110px;
						background: rgba(0, 0, 0, 0.11);
						position: absolute;
						left: 0;
						bottom: 0;
						right: 0;
						color: white;
						font-size: 44px;
						text-shadow: 0px 2px 4px rgba(123, 123, 123, 0.5);
					}
				}
			}

			.right {
				padding-left: 66px;

				div {
					padding-bottom: 14px;

					img {
						width: 221px;
						height: 122px;
					}
				}

				div:nth-child(4) {
					padding: 0;
				}
			}
		}

		.details_box_introduce {
			text-align: left;
			padding-bottom: 110px;

			.pd_introduce_title {
				font-size: 44px;
			}

			.pd_introduce_text {
				padding-top: 34px;
				font-size: 22px;
				color: #666666;
			}
		}

		.correlation {
			padding-bottom: 100px;
			.correlation_title {
				font-size: 44px;
				padding-top: 0px;
			}

			.correlation_box {
				padding: 0px 0 10px 0;
			}
		}
	}

	.imgBorder {
		transform: 2s;
		border: #932224 solid 4px;
	}
	.correlation_box{
		width: 100%;
		margin: 0 auto;
		position: relative;
		.correlation_conten{
			flex-wrap: nowrap;
			padding-bottom: 5px;
			position: absolute;
			width: 100%;
			height: 100%;
			left: 0;
			right: 0;
			transition: all .5s linear 0s;
			>.corre_ContenR{
				margin-left: 0%!important;
			}
			>.corre_Conten{
				padding-bottom: 15px;
				width: 32%;
				max-width: 32%;
				min-width: 32%;
				background-color: #FFFFFF;
				text-align: center;
				box-shadow: 2px 5px 4px 1px rgba(190, 190, 190, 0.5);
				margin:0 1%;
				z-index: 9;
				>.corre_icon{
					width: 100%;
					height: 351px;
				}
				>.corre_box{
					width: 314px;
					height: 175px;
					margin: 0 auto;
					h3{
						font-size: 28px;
						padding: 24px 0 35px 0;
					}
					>.corre_Title{
						border-top: 1px solid #D1D1D1;
						padding: 17px 0 0 0;
						font-size: 18px;
						color: #666666;
						/deep/ span{
							overflow: hidden;
							text-overflow: ellipsis;
							display: inline-block;
							display: -webkit-box;
							-webkit-box-orient: vertical;
							-webkit-line-clamp: 2;
							font-size: 18px;
							text-indent: 0!important;
						}
					}
				}
				
			}
		}
	}
	.correlation_l,.correlation_r{
		width: 66px;
		height: 66px;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		z-index: 999;
		cursor: pointer;  /*鼠标悬停变小手*/
		
	}
	.correlation_l{
		left: -33px;
	}
	.correlation_r{
		right: -33px;
	}
</style>

<template>
	<div class="content">
		<div class="box" v-if="obj">
			<div class="details_box">
				<div class="left">
					<div class="icon_image icon_box">
						<img :src="obj.en_images[imgIndex]"  style="object-fit: cover;"/>
						<div class="text">
							<span>{{obj.en_name}}</span>
						</div>
					</div>
				</div>
				<div class="right">
					<div v-for="(item,index) in obj.en_images" :key="index" @click="imgShow(index)">
						<img :src="item" :class="index==imgIndex?'imgBorder':''" class="cursorS"  style="object-fit: cover;"/>
					</div>
				</div>
			</div>
			<div class="details_box_introduce">
				<div class="pd_introduce_title">{{$t('newText.msg5')}}</div>
				<div class="pd_introduce_text">
					<div v-html="obj.content"></div>
				</div>
			</div>
			<div class="correlation">
				<div class="correlation_title">{{$t('newText.msg6')}}</div>
				<div class="correlation_box">
					<div class="correlation_l icon_image" @click="tramove(1)">
						<img src="../../../assets/correlationl.png" >
					</div>
					<div class="correlation_r icon_image" @click="tramove(0)">
						<img src="../../../assets/correlationr.png" >
					</div>
					<div style="overflow: hidden;position: relative;height: 29.29688vw;">
						<div class="correlation_conten flex_just_disp" :style="'left:-'+tralret+'%;transform: translateX('+tralret+')%'">
							<div class="corre_Conten cursorS"  @click.stop="productDisplayList(item.id)" :class="[index==traindex?'corre_ContenR':'']" v-for="(item,index) in contentList" :key="index">
								<div class="corre_icon icon_image">
									<img :src="item.en_images[0]" style="object-fit: cover;">
								</div>
								<div class="corre_box">
									<h3>{{item.en_name}}</h3>
									<div class="corre_Title">
										<p v-html="item.content"></p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				obj:'',
				imgIndex:0,
				contentList: [],
				tralret:0,
				traindex:0,
				id:0
			}
		},
		mounted() {
			this.id= this.$route.query.id;
			this.productDisplayList();
		},
		watch:{
			traindex(val){
				this.tralret=34*val;
				console.log(this.tralret)
			}
		},	
		methods: {
			tramove(index){
				let num=this.contentList.length-4;
				if(index==0){
					if(this.traindex>num)return
					this.traindex++;
				}else{
					if(this.traindex===0)return;
					this.traindex--;
				}
			},
			async productDisplayList(id) {
				try {
					let _this = this
					let params = {
						id: id||this.id,
					}
					let res = await this.$axios.get('product/detail', {
						params: params
					});
					// console.log(res)
					if (res.code == 1) {
						this.obj=res.data;
						this.productList(id||this.id);
					}
				} catch (error) {
					console.log(error);
				}
			},
			async productList(id) {
				try {
					let _this = this;
					this.contentList=[];
					let params = {
						page: 1,
					}
					let res = await this.$axios.get('product/index', {
						params: params
					});
					console.log(res)
					if (res.code == 1) {
						res.data.forEach((item)=>{
							if(item.id!=id){
								this.contentList.push(item);
							}
						})
						window.scroll(0, 0);
						this.contentList.forEach(item=>{
							item.update_time=this.$util.formatTimeTwo(item.update_time, 'Y-M-D')
						})
						console.log(this.contentList);
					}
				} catch (error) {
					console.log(error);
				}
			},
			imgShow(i) {
				this.imgIndex=i
			}
		}
	}
</script>
